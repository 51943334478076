<template>
  <div class="msg-center-page">
    <div class="expire-tip" v-if="expireTimes !== 99999 && expireTimes <= 30">
      <template v-if="expireTimes >= 0">
        您的监控权益还剩<span class="red-text">{{ expireTimes }}天</span>到期，到期后系统会清空已监控的企业。请及时<span class="bule-text"
          @click="contactCustomerService">联系客服</span>续费。
      </template>
      <template v-else>
        <template v-if="expireTimes == -1">
          您的监控权益已过期，已停止新消息更新。请及时<span class="bule-text" @click="contactCustomerService">联系客服</span>续费。
        </template>
        <template v-if="expireTimes == -2">
          您暂无监控权益，请<span class="bule-text" @click="contactCustomerService">联系客服</span>开通。
        </template>
      </template>
    </div>
    <div class="search-main">
      <div class="search-input">
        <el-input placeholder="请输入企业名称" prefix-icon="el-icon-search" size="small" clearable v-model="searchInfo"
          @keyup.enter.native="toSearch" @input="toSearch" />
      </div>
    </div>
    <div class="content-main" v-loading="pageLoading">
      <template v-if="listData.length > 0">
        <div v-for="(item, index) in listData" :key="index" class="list-item">
          <div class="enterprise-name">
            <span class="text-title" @click="jumpEnterpriseInfo(item.enterpriseInfoId)">
              {{ item.enterpriseName }}
            </span>
            <template v-if="item.ifMonitor === 1">
              <div class="monitor-status monitor-status-green">
                已监控
              </div>
            </template>
            <template v-else>
              <div class="monitor-status monitor-status-red">
                未监控
              </div>
            </template>
          </div>
          <div v-for="(its, idx) in item.enterpriseSearchMessageVOList" :key="idx"
            :class="{ 'msg-list-item': true, 'last-item': item.enterpriseSearchMessageVOList.length === idx + 1 }">
            <div class="msg-title">
              <span>
                {{ its.detailCategory }}
              </span>
              <span style="color: #999999">
                {{ its.detailDate }}
              </span>
            </div>
            <div class="msg-content">
              <div class="content-item" v-for="(it, id) in its.detailJson" :key="id">
                <div class="item-label">{{ it.label }}：
                </div>
                {{ it.value }}
              </div>
              <div class="risk-main">
                <div class="risk-label"
                  :style="{ 'background': colorDict[its.riskLabel].bgColor, 'color': colorDict[its.riskLabel].color, }">
                  {{ riskLabelDict[its.riskLabel] }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="item.ifMoreThanMsgShowLimit === 1" class="more-main" @click="clickMore(item.enterpriseInfoId)">
            查看此企业更多动态
            <i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
      </template>
      <template v-else>
        <el-empty :image-size="120"></el-empty>
      </template>
    </div>
    <div class="pagina-main">
      <el-pagination background layout="total, sizes, prev, pager, next, jumper" :total="total"
        :page-sizes="[10, 20, 50, 100]" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <customerServiceDialog ref="customerServiceRef" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as api from '@/api'
import { debounce } from 'lodash'
let updateTimer = null
import customerServiceDialog from "@/components/customerServiceDialog.vue";
export default {
  components: {
    customerServiceDialog
  },
  data() {
    return {
      options: [],
      searchObj: {},
      searchInfo: '',
      listData: [],
      riskLabelDict: {
        1: '提示',
        2: '正向',
        3: '低风险',
        4: '中风险',
        5: '高风险',
      },
      colorDict: {
        1: {
          bgColor: '#E7F4EB',
          color: '#19A058 ',
        },
        2: {
          bgColor: '#DFECFF',
          color: '#4088EB ',
        },
        3: {
          bgColor: '#FDF1E1',
          color: '#FF8321 ',
        },
        4: {
          bgColor: '#F8EAF3',
          color: '#E23BB8',
        },
        5: {
          bgColor: '#FFEAE8',
          color: '#FF2000',
        },
      },
      total: 0,
      pageNo: 1,
      pageSize: 10,
      pageLoading: false,
      expireTimes: 99999,
    }
  },
  methods: {
    toSearch: debounce(function () {
      this.pageNo = 1
      this.pageSize = 10
      this.getRiskMonitorList()
    }, 300),
    getRiskMonitorList() {
      this.pageLoading = true
      let params = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      }
      if (this.searchInfo) {
        params.enterpriseName = this.searchInfo
      }
      api.riskMonitor.getMonitorEnterpriseSearchMessageInfoPage(params).then(({ success, data }) => {
        if (success) {
          this.listData = data.data.pageResult.records
          this.total = Number(data.data.pageResult.total)
        }
        this.pageLoading = false
      }).catch(() => {
        this.pageLoading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getRiskMonitorList()
    },
    handleCurrentChange(val) {
      this.pageNo = val
      this.getRiskMonitorList()
    },
    clickMore(enterpriseId) {
      this.$router.push({
        path: '/accountCenter/msgCenter/enterpriseAllMsg',
        query: {
          enterpriseId
        }
      })
    },
    getHighestRightsRestDays() {
      api.riskMonitor.getHighestRightsRestDays().then(({ data, success }) => {
        if (success) {
          this.expireTimes = data.data
        }
      })
    },
    contactCustomerService() {
      this.$refs.customerServiceRef.showDialog(true)
    },
    jumpEnterpriseInfo(enterpriseInfoId) {
      let url = process.env.VUE_APP_MODE === 'prod' ? `https://pate.zzt.com.cn/enterprise/detail?id=${enterpriseInfoId}&token=${this.$store.state.token}` : `https://test-pate.zzt.com.cn/enterprise/detail?id=${enterpriseInfoId}&token=${this.$store.state.token}`
      window.open(url)
    },
  },
  mounted() {
    this.getRiskMonitorList()
    this.getHighestRightsRestDays()
  },
  computed: {
    ...mapState(['userInfo'])
  },
}
</script>

<style lang="scss" scoped>
.msg-center-page {
  .expire-tip {
    background: #FFF2E8;
    font-size: Translate(14px);
    color: #333333;
    height: Translate(40px);
    display: flex;
    align-items: center;
    margin: Translate(20px) Translate(20px) 0 Translate(20px);
    box-sizing: border-box;
    padding-left: Translate(10px);

    .bule-text {
      color: #4088EB;
      cursor: pointer;
    }

    .red-text {
      color: #FF8321;
    }
  }

  .search-main {
    padding: 0px Translate(20px);
    min-height: Translate(74px);
    // border-bottom: Translate(16px) solid #f5f6f9;
    display: flex;
    align-items: center;

    .search-input {
      width: Translate(300px);
    }
  }

  .content-main {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0 Translate(20px);

    .list-item {
      margin-bottom: Translate(30px);

      .enterprise-name {
        font-size: Translate(20px);
        color: #333333;
        font-weight: 500;
        margin-bottom: Translate(8px);
        display: flex;
        align-items: center;
        // justify-content: space-between;

        .text-title {
          cursor: pointer;

          &:hover {
            color: #4088EB;
          }
        }


        .monitor-status {
          width: Translate(46px);
          line-height: Translate(18px);
          font-size: Translate(12px);
          display: flex;
          justify-content: center;
          margin-left: Translate(5px);
          position: relative;
          top: Translate(2px);
          box-sizing: border-box;

          &-green {
            color: #27B900;
            border: Translate(1px) solid #27B900;
          }

          &-red {
            color: #FF1F00;
            border: Translate(1px) solid #FF1F00;
          }

          .iconfont {
            font-size: Translate(12px);
            margin-right: Translate(4px);
          }
        }
      }

      .msg-list-item {
        padding-left: Translate(10px);
        box-sizing: border-box;
        position: relative;

        &::before {
          content: '';
          display: inline-block;
          width: Translate(1px);
          height: calc(100% - Translate(5px));
          background-color: #EBEBEB;
          position: absolute;
          left: 0;
          bottom: Translate(-7px);
        }

        &::after {
          content: '';
          display: inline-block;
          width: Translate(6px);
          height: Translate(6px);
          border-radius: Translate(3px);
          // background-color: #EBEBEB;
          background-color: #D7D7D7;
          position: absolute;
          left: Translate(-3px);
          top: Translate(7px);
        }

        .msg-title {
          font-size: Translate(14px);
          color: #333333;
          font-weight: 500;
          margin-bottom: Translate(8px);
          display: flex;
          justify-content: space-between;
        }

        .msg-content {
          background: #F9F9F9;
          box-sizing: border-box;
          padding: Translate(18px) Translate(100px) Translate(18px) Translate(20px);
          font-size: Translate(14px);
          color: #333333;
          line-height: Translate(24px);
          position: relative;
          border-bottom: Translate(20px) solid #FFFFFF;

          .risk-main {
            height: 100%;
            position: absolute;
            right: Translate(30px);
            top: Translate(0px);
            display: flex;
            justify-content: center;
            align-items: center;

            .risk-label {
              border-radius: Translate(4px);
              width: Translate(64px);
              height: Translate(28px);
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: Translate(12px);
            }
          }

          .content-item {
            line-height: Translate(24px);
            color: #333333;
            font-size: Translate(14px);
            padding-right: Translate(80px);

            .item-label {
              color: #999999;
              display: inline-block;
            }

          }
        }
      }

      .last-item {
        .msg-content {
          border-bottom: 0px;
        }
      }

      .more-main {
        font-size: Translate(14px);
        color: #4088EB;
        cursor: pointer;
        margin-top: Translate(10px);
        margin-left: Translate(10px);
      }
    }
  }

  .pagina-main {
    display: flex;
    justify-content: end;
    padding-bottom: Translate(40px);
    padding-right: Translate(20px);
  }
}
</style>